<template>
    <div class="columns">
        <div class="column is-full">
            <div style="display: flex;">
                <RadioButtonList :entries="viewOptions" label="Filtrera" :horizontal="true" @change="onChangeViewOption" style="margin-left:10px;" />
                <DropDown :options="years" @on-change="onYearChange" state="ready" :value="filterYear" style="margin-left: 10px;" />
                <DropDown :options="months" @on-change="onMonthChange" state="ready" :value="filterMonth" style="margin-left: 10px;" />
            </div>
            <TransactionList :transactions="transactionsFilteredByClient" />
            <i style="margin: 10px 0 0 8px;" class="fas fa-flip-horizontal fa-level-up-alt" />
            <button style="margin: 10px 0 0 10px;" v-if="true" class="button is-info is-outlined is-small" @click="click">
                Skapa skatteuppdrag
            </button>
            <button style="margin: 10px 0 0 10px;" v-if="true" class="button is-info is-outlined is-small" @click="clickBookSkv">
                Bokför 1630
            </button>
        </div>
    </div>
</template>

<script>
import TransactionList from "./transactionList";
import RadioButtonList from "../../../../components/radioButtonList";
import DropDown from "../../../../components/dropdown";
import moment from "moment";

export default {
    props: ["transactions"],
    components: {
        TransactionList,
        RadioButtonList,
        DropDown,
    },
    data: function() {
        return {
            viewOptions: [
                { name: "Alla", value: "all" },
                { name: "Preliminärskatt", value: "preliminaryTax" },
                { name: "Skatt", value: "tax" },
                { name: "Slutlig skatt enskilda firmor", value: "taxprivate" },
                { name: "Arbetsg.avg.", value: "aga" },
                { name: "Moms", value: "vat" },
            ],
            viewOption: "all",
            years: [
                { name: "2019", value: 2019 },
                { name: "2020", value: 2020 },
                { name: "2021", value: 2021 },
                { name: "2022", value: 2022 },
                { name: "2023", value: 2023 },
                { name: "2024", value: 2024 },
            ],
            months: [
                { name: "Jan", value: 1 },
                { name: "Feb", value: 2 },
                { name: "Mar", value: 3 },
                { name: "Apr", value: 4 },
                { name: "Maj", value: 5 },
                { name: "Jun", value: 6 },
                { name: "Jul", value: 7 },
                { name: "Aug", value: 8 },
                { name: "Sep", value: 9 },
                { name: "Okt", value: 10 },
                { name: "Nov", value: 11 },
                { name: "Dec", value: 12 },
            ],
            filterYear: undefined,
            filterMonth: undefined,
        };
    },
    created() {
        const actualYear = moment().year();
        const actualMonth =
            moment()
                .add(1, "M")
                .month() + 1;
        this.filterYear = actualYear;
        this.filterMonth = actualMonth;
    },
    watch: {},
    methods: {
        onYearChange(year) {
            this.$emit("filterYearChanged", year);
        },
        onMonthChange(month) {
            this.$emit("filterMonthChanged", month);
        },
        click() {
            const data = this.transactionsMaped.filter(item => item.check === true);
            this.$emit("create", data);
        },
        clickBookSkv() {
            const data = this.transactionsMaped.filter(item => item.check === true);
            this.$emit("book", data);
        },
        onChangeViewOption: function(item) {
            this.viewOption = item.value;
        },
    },

    computed: {
        transactionsMaped: function() {
            return this.transactions.map(item => ({
                clientId: item.clientId,
                taxAccountTransactionId: item.taxAccountTransactionId,
                number: item.number,
                name: item.name,
                companyType: item.companyType,
                text: item.text,
                taxStatus: item.taxStatus,
                date: item.date,
                amount: this.$options.filters.swedishNumberFormat(item.amount),
                amountRegistered: this.$options.filters.swedishNumberFormat(item.amountRegistered),
                check: false,
                id: item.id,
                paymentVoucherNumber: item.paymentVoucherNumber,
                taxWithdrawalVoucherNumber: item.taxWithdrawalVoucherNumber,
                upcoming: item.upcoming ? "Kommande" : "Utförd",
                taxType: item.taxType,
            }));
        },
        transactionsFiltered: function() {
            if (this.viewOption == "taxprivate") {
                return this.transactionsMaped.filter(item => {
                    return (
                        item.companyType == 1 &&
                        (item.text.toLowerCase() == "avdragen skatt" ||
                            item.text.toLowerCase() == "slutlig skatt" ||
                            item.text.toLowerCase() == "tillgodoförd debiterad preliminärskatt" ||
                            item.text.toLowerCase().includes("preliminär skattereduktion"))
                    );
                });
            } else {
                return this.transactionsMaped.filter(item => {
                    return (
                        (this.viewOption == "preliminaryTax" && item.text == "Debiterad preliminärskatt") ||
                        (this.viewOption == "vat" && item.text.toLowerCase().includes("moms")) ||
                        (this.viewOption == "tax" && item.text.toLowerCase().includes("skatt") && !item.text.toLowerCase().includes("prel")) ||
                        (this.viewOption == "aga" && item.text.toLowerCase().includes("arbetsgivar")) ||
                        this.viewOption == "all"
                    );
                });
            }
        },
        transactionsFilteredByClient() {
            return this.isGlobalClientSelected ? this.transactionsFiltered.filter(trans => trans.clientId === this.$store.state.topBar.selectedClient.value) : this.transactionsFiltered;
        },
        isGlobalClientSelected() {
            return this.$store.getters["topBar/isClientSelected"];
        },
    },
};
</script>
