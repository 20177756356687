<template>
    <section class="section">
        <div class="container">
            <UpcomingContainer :transactions="transactions" @create="create" @book="book" @filterYearChanged="onFilterYearChanged" @filterMonthChanged="onFilterMonthChanged" />
        </div>
    </section>
</template>

<script>
import Api from "../taxAccount.api";
import UpcomingContainer from "./ui/upcoming.container";
import moment from "moment";

export default {
    data: function() {
        return {
            transactions: [],
            filterYear: undefined,
            filterMonth: undefined,
        };
    },
    components: { UpcomingContainer },

    async created() {
        const actualYear = moment().year();
        const actualMonth =
            moment()
                .add(1, "M")
                .month() + 1;
        this.filterYear = actualYear;
        this.filterMonth = actualMonth;
        this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
        document.title = "Skattekonto - Kommande";
    },
    methods: {
        async onFilterYearChanged(year) {
            this.filterYear = year;
            this.transactions = [];
            this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
        },
        async onFilterMonthChanged(month) {
            this.filterMonth = month;
            this.transactions = [];
            this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
        },
        async create(data) {
            var taxPrevYear = data.find(item => item.taxType === "Skatt föregående år");
            if (taxPrevYear == undefined) {
                const confirmed = await this.$dialog
                    .title("Skatteupdrag")
                    .html()
                    .confirm(`Du kommer skapa ${data.length} skatteuppdrag`);
                if (confirmed) {
                    const result = await Api.createUpcoming(data);
                    const type = result.messageType == 0 ? "success" : result.messageType == 1 ? "info" : "error";
                    this.$toasted.show(result.message, {
                        theme: "bubble",
                        duration: null,
                        position: "bottom-center",
                        type: type,
                        keepOnHover: true,
                        action: {
                            text: "Cancel",
                            onClick: (e, toastObject) => {
                                toastObject.goAway(0);
                            },
                        },
                    });
                    this.transactions = [];
                    this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
                }
            } else {
                const groupedMap = data.reduce((entryMap, e) => entryMap.set(e.clientId, [...(entryMap.get(e.clientId) || []), e]), new Map());
                const count = groupedMap.size;
                if (count > 1) {
                    await this.$dialog.html().alert(`Välj bara en klient åt gången, inget skatteuppdrag kommer skapas.`);
                    return;
                }
                var sum = data.reduce(function(prev, cur) {
                    const amount = parseFloat(cur.amount.replace(",", ".").replaceAll(" ", ""));
                    return prev + amount;
                }, 0);
                if (sum >= 0) {
                    await this.$dialog.html().alert(`Det blir ett överskott på kontot med ${sum} SEK, inget skatteuppdrag kommer skapas.`);
                } else {
                    const confirmed = await this.$dialog
                        .title("Skatteupdrag")
                        .html()
                        .confirm(`Du kommer skapa ett skatteuppdrag på <strong>${-sum} SEK</strong>`);
                    if (confirmed) {
                        const result = await Api.createUpcoming(data);
                        const type = result.messageType == 0 ? "success" : result.messageType == 1 ? "info" : "error";
                        this.$toasted.show(result.message, {
                            theme: "bubble",
                            duration: null,
                            position: "bottom-center",
                            type: type,
                            keepOnHover: true,
                            action: {
                                text: "Cancel",
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                },
                            },
                        });
                        this.transactions = [];
                        this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
                    }
                }
            }
        },
        async book(data) {
            await Api.bookUpcoming(data);
            this.transactions = [];
            this.transactions = await Api.getUpcoming(this.filterYear, this.filterMonth);
        },
    },
};
</script>
